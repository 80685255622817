import React, { useState, useEffect, useCallback } from "react";
import { Table as AntTable, Checkbox, Pagination } from "antd";
import { AiOutlineFilter } from "react-icons/ai";

type TableData = {
  columns: Array<any>;
  dataSource: Array<any>;
  rowKey: string;
  loadNextProds?: any;
  isPaginated: boolean;
  recordCount?: any;
  isServerRendered?: any;
  onChange?: any;
  className?: any;
  resetInputField?: any;
  rowClassName?: any;
  loading?: any;
  showColumnFilter?: any;
  onSelectedColumnsChange?: any;
};

const Table = ({
  columns,
  dataSource,
  rowKey,
  loadNextProds,
  isPaginated,
  recordCount,
  isServerRendered,
  onChange,
  className,
  resetInputField,
  rowClassName,
  loading,
  showColumnFilter = false,
  onSelectedColumnsChange,
}: TableData) => {
  const totalItems: any = localStorage.getItem("totalItems");
  const [index, setIndex] = useState(1);
  const [, setPrevIndex] = useState(10);
  const [resetPagination, setResetPagination] = useState(false);
  const [showColumnList, setShowColumnList] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState(() =>
    columns.map((column) => column.dataIndex)
  );
  const [filteredColumns, setFilteredColumns] = useState(() =>
    columns.filter((column) => selectedColumns.includes(column.dataIndex))
  );
  const [pageSize, setPageSize] = useState<any>(10);
  useEffect(() => {
    setFilteredColumns(
      columns.filter((column) => selectedColumns.includes(column.dataIndex))
    );
    if (onSelectedColumnsChange) {
      onSelectedColumnsChange(selectedColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, selectedColumns]);

  const handleCheckboxChange = useCallback(
    (checkedValues: any) => {
      const newSelectedColumns = columns
        ?.map((column) => column?.dataIndex)
        ?.filter((dataIndex) => checkedValues?.includes(dataIndex));
      setSelectedColumns(newSelectedColumns);

      setFilteredColumns(
        columns.filter((column) =>
          newSelectedColumns.includes(column.dataIndex)
        )
      );
      if (onSelectedColumnsChange) {
        onSelectedColumnsChange(newSelectedColumns);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns]
  );

  useEffect(() => {
    setResetPagination(resetInputField);
    if (localStorage.getItem("currPageIndex")) {
      let prevIndex: any = localStorage.getItem("currPageIndex");
      setIndex(prevIndex * 1 + 1);
    }
    if (localStorage.getItem("passedIndexIncomingShipmentListing")) {
      let prevIndex: any = localStorage.getItem(
        "passedIndexIncomingShipmentListing"
      );
      setIndex(prevIndex * 1 + 1);
    }
  }, [resetInputField]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (showColumnList && !event.target.closest(".column-filter-wrapper")) {
        setShowColumnList(false);
      }
    };

    if (showColumnList) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showColumnList]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (showColumnList && !event.target.closest(".column-filter-wrapper")) {
        setShowColumnList(false);
      }
    };

    if (showColumnList) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showColumnList]);

  return (
    <div className="relative">
      <div>
        {showColumnFilter && (
          <div className="flex justify-end mb-2 column-filter-wrapper">
            <AiOutlineFilter
              className="cursor-pointer"
              size={17}
              title="Filter Columns"
              onClick={() => setShowColumnList((prev) => !prev)}
            />
            {showColumnList && (
              <div className="absolute w-60 mt-5 t-0 l-0 z-10 bg-white shadow-md border mb-5 rounded-md px-4 py-2 max-h-60 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-primary-light scrollbar-thumb-rounded-md">
                <Checkbox.Group
                  onChange={handleCheckboxChange}
                  value={selectedColumns}
                  style={{ width: "100%" }}
                >
                  {columns.map((column) => (
                    <div key={column?.dataIndex}>
                      <Checkbox value={column.dataIndex}>
                        {column.title}
                      </Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
            )}
          </div>
        )}
        <AntTable
          scroll={{ x: true }}
          columns={showColumnFilter ? filteredColumns : columns}
          dataSource={dataSource}
          size="middle"
          rowKey={rowKey}
          onChange={onChange}
          loading={loading}
          className={className}
          rowClassName={rowClassName}
          pagination={
            isServerRendered
              ? false
              : isPaginated && {
                  simple: true,
                  position: ["bottomLeft"],
                  onChange: (index) => {
                    setIndex(index * 1);
                    setPrevIndex(10 * index);
                  },
                }
          }
        />
      </div>
      {!isServerRendered ? (
        recordCount && isPaginated ? (
          <h1 className="text-xs font-medium absolute bottom-5 ml-5">
            Showing {index * 10 <= recordCount ? 10 : recordCount % 10} of{" "}
            {recordCount} items
          </h1>
        ) : dataSource && dataSource.length > 0 && isPaginated ? (
          totalItems ? (
            <h1 className="text-xs font-medium absolute bottom-5 ml-5">
              Showing {index * 10 <= totalItems ? 10 : totalItems % 10} of{" "}
              {totalItems} items
            </h1>
          ) : (
            <h1 className="text-xs font-medium absolute bottom-5 ml-5">
              Showing{" "}
              {index * 10 <= dataSource?.length ? 10 : dataSource?.length % 10}{" "}
              of {dataSource?.length} items
            </h1>
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {isServerRendered && isPaginated && dataSource?.length > 0 && (
        <div className="flex flex-col-reverse ltmob:flex-row ltmob:items-center mt-5 mb-6 ml-5">
          <div className="mb-3 ltmob:mb-0">
            {recordCount ? (
              <h1 className="text-xs font-medium ml-2 ltmob:ml-0">
                Showing{" "}
                {pageSize > 10
                  ? index * pageSize <= recordCount
                    ? pageSize
                    : recordCount % pageSize
                  : index * 10 <= recordCount
                  ? 10
                  : recordCount % 10}{" "}
                of {recordCount} items
              </h1>
            ) : (
              <h1 className="text-xs font-medium mr-2 ">
                Showing{" "}
                {index * 10 <= dataSource?.length
                  ? 10
                  : dataSource?.length % 10}{" "}
                of {dataSource?.length} items
              </h1>
            )}
          </div>
          <div className="mb-3 ml-0 ltmob:ml-4 ltmob:mb-1">
            <Pagination
              size="small"
              defaultPageSize={pageSize}
              showQuickJumper
              showSizeChanger={true}
              current={resetPagination ? 1 : index}
              defaultCurrent={index}
              total={recordCount ? recordCount : dataSource?.length}
              onChange={(index, pageSize) => {
                if (index > 1) {
                  setResetPagination(false);
                }
                setIndex(index * 1);
                setPrevIndex(10 * index);
                setPageSize(pageSize);
                if (isServerRendered) {
                  loadNextProds(index, pageSize);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
Table.defaultProps = {
  loadNextProds: () => {},
};
export default Table;
